<template>
        <div class="list">
            <ul class="board_list">
                <li v-for="(item,index) in list" :key="index" class="border-bottom fadeInUp" :class="{'delay':index>0,'s016':index==1,'s02':index==2,'s03':index>=3}">
                    <router-link :to="{path:goDetail(item),query:{name:title}}">
                        <h6 class="fw-bold">{{item.title}}</h6>
                        <div class="info mt-1 md-mt-3">
                            <span class="text-grey right_line">{{item.date}}</span>
                            <b v-if="item.company" class="right_line">
                                {{item.company}}
                            </b>
                            <span class="text-grey right_line" v-if="item.type1">{{item.type1}}</span>
                            <span class="text-grey right_line" v-if="item.type2">{{item.type2}}</span>
                            <span class="text-grey right_line" v-if="item.type">{{item.type}}</span>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
</template>

<script>
export default {

    data(){
        return{
        }
    },
    props: {
        list:{
            type: Array
        },
        title:{
            type:String
        },
        cate:{
            type: String,
            default: "",
        }
    },
    methods: {
        // clickItem(item){
        //     if(item.link){
        //         window.open('about:blank').location.href = item.link;
        //     }else{
        //         this.$router.push({path: `${this.$route.path}/${item.idx}`})
        //     }
        // },
        goDetail(item){
            if( this.cate != "" ){
                return `/notice/${this.cate}/${item.idx}`;
            }else{
                return `/notice/${item.idx}`;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .board_list{
        li{
        text-align: left;

            a{
            display: block;
            font-size: 80%;
            padding: 14px 0;

            h4{
                font-size: 18px;
            }
            }
        }
    }
    .right_line:last-of-type::after{
        display:none;
    }
</style>