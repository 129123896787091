<template>
    <div class="main">
      <div class="container pt-4">
        <div class="map" ref="mapContainer">
            <!-- <img :src="imageUrl" class="zoomable-image" /> -->
            <div class="zoomable-image" :class="{'misa':location==='misa','gangnam':location==='gangnam'}">
              <span class="badge bagde-primary text-center franchise map-item shadow" @click="$refs.InfoPopup.item_id = 0, $refs.InfoPopup.pp = true">평일 당일권<h6>5,000</h6></span>
              <span class="badge bagde-primary text-center common map-item shadow" @click="$refs.InfoPopup.item_id = 1, $refs.InfoPopup.pp = true"><h6>3,000</h6></span>
              
              <div class="myPostion"></div>
            </div>
        </div>
        <StepBox class="position-relative zindex-1" />

        <TabBar />

        <div class="dimmed" v-show="$store.state.sideOpen" @click="sideOpen()"></div>
        <SideNav :class="{'on':$store.state.sideOpen}"></SideNav>

        <InfoPopup ref="InfoPopup" />
        
        <div class="zoom-in-out position-fixed bg-white shadow">
          <div class="px-2 py-1 border-bottom"><i class="far fa-plus text-gray-400"></i></div>
          <div class="px-2 py-1"><i class="far fa-minus text-primary" @click="Minus()"></i></div>
        </div>

        <i class="far fa-crosshairs position-fixed end-0 p-2 m-3 rounded-circle bg-white text-primary shadow" style="bottom:60px" @click="scrollXY($route.params.location)"></i>
      </div>
      
    </div>
  </template>
  
  <script>
  import SideNav from '@/components/common/SideNav.vue'
  import TabBar from '@/components/common/TabBar.vue'
  import StepBox from '@/components/StepBox.vue'
  import InfoPopup from '@/components/reservation/InfoPopup.vue'
  
  export default {
    name: 'Main',
    components: {
      StepBox, InfoPopup, SideNav, TabBar
    },
    data() {
      return {
        location:this.$route.params.location,
      }
    },
    methods:{
      scrollXY(location){
        const mapContainer = this.$refs.mapContainer;
        
        if(location ==='misa'){
          mapContainer.scrollTop = 2100;
          mapContainer.scrollLeft = 900;
        }else{
          mapContainer.scrollTop = 550;
          mapContainer.scrollLeft = 830;
        }
      },
      Minus(){
        this.$router.push(`/main/${this.location}`)
      },
      
      sideOpen(){
        this.$store.commit('sideOpenFn',false)
      }
    },
    mounted(){
      this.scrollXY(this.$route.params.location);

      window.onload = () =>{
        this.scrollXY();
      }

    }
  };
  </script>
  
<style lang="scss" scoped>

.dimmed{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 999;
}
.zoom-in-out{
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  border-radius: 4px;
  width: 32px;
  text-align: center;
}
.map {
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: fixed;
  left: 0;
  top: 0;
}

.zoomable-image {
    width: 2000px;
    height: 2000px;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    object-position: center center;
    position: relative;
    min-width: 100%;
    min-height: 100%;
    &.misa{
      background-image: url('../../assets/img/map/misa (1).png');
      .map-item.franchise{ 
      left: 55%;
      bottom: 22%;
      }
      .map-item.common{
      left: 48%;
      bottom: 21%;
      }
      .myPostion{
        left: 59%;
        bottom: 16%;
      }
    }
    &.gangnam{
      background-image: url('../../assets/img/map/gangnam (1).png');
    
      .map-item.franchise{ 
        left: 54%;
        bottom: 51%;
      }
      .map-item.common{
        left: 52%;
        bottom: 50%;
      }
      .myPostion{
        left: 51%;
        bottom: 51%;
      }
    }
}

.myPostion{
  position: absolute;
  background-color: #558bff;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  z-index: 10;
  border: 2px solid #fff;
  box-shadow: 0 0 9px 3px #558bffab;
}
.map-item{
  position: absolute;
  display: flex;
  color: #fff;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  left: 1070px;
  bottom: 440px;
  
  border: 2px solid #00000033;
  
  &.franchise{ 
    background-color: #558bffe6;
  }
  &.common{
    background-color: #939090e3;
  }
  &.franchise::after{color: #558bffe6;}

  &.common::after{color:#939090e3;}
  
  &::after{
    position: absolute;
    z-index: 1;
    bottom: -4px;
    left: calc(50% - 6px);
    content: " ";
    height: 0;
    border-bottom: 6px solid;
    border-left: 6px solid rgba(0, 0, 0, 0);
    border-right: 6px solid rgba(0, 0, 0, 0);
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
  }
}


</style>
  