<template>
    <div class="sideNav shadow py-3">
        <div class="flex-between-center px-3 mb-2" @click="$router.push('/mypage')">
            <div class="d-flex flex-column">
                <p class="fs-px-12">안녕하세요.</p> 
                <div class="d-flex">
                    <h5 class="me-1 text-primary fw-bold">홍길동</h5>님
                </div>
            </div>
            <i class="far fa-chevron-right text-primary"></i>
        </div>
        <div class="px-3">
            <router-link to="/mypage/added-car" class="badge bg-primary w-100 text-center py-3 mb-2">차량번호 108 가 1749</router-link>
            <router-link to="/mypage/my-parking" class="badge border border-primary text-primary w-100 text-center py-3">내 공유주차장</router-link>
        </div>
        <a href="#" class="banner"><img src="@/assets/img/banner.png" class="w-100 mt-4"></a>
        <ul class="p-3 sideNavList">
            <li class="py-3" @click="$router.push('/mypage/assets')"><i class="fal fa-parking me-2"></i>내 주차권</li>
            <li class="py-3" @click="$router.push('/mypage/history')"><i class="fal fa-list me-2" style="background-color: lightblue;"></i>주차장 이용내역</li>
            <li class="py-3" @click="$router.push('/mypage/favorite')"><i class="fal fa-star me-2" style="background-color: orange;"></i>즐겨찾기</li>            
        </ul>
        <div class="bg-gray-300" style="height:5px"></div>
        <ul class="p-3 sideNavList">
            <li class="py-3" @click="$router.push('/mypage/push');"><i class="fal fa-bell me-2" style="background-color: lightsteelblue;"></i>알림</li>
            <li class="py-3" @click="$router.push('/mypage/setting')"><i class="fal fa-cog me-2 bg-gray-500"></i>환경설정</li>
        </ul>
    </div>
</template>
<script>
export default {
  name: 'Main',
}
</script>
<style lang="scss" scoped>
.sideNav{
    max-width: 90%;
    min-width: 80%;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    transform: translateX(-120%);
    transition: transform .3s;
    &.on{
        transform: translateX(0);
    }
    .sideNavList{
        font-size: 14px;
        font-weight: 600;
        color: #373737;
        i{
            min-width: 34px;
            text-align: center;
            background-color: #558bff;
            padding: 8px;
            color: #fff;
            border-radius: 6px;
            font-size: 16px;
            margin-right: 21px !important;
        }
    }
}
</style>