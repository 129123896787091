<template>
    <div class="main">
        <div class="container pt-4">
            <StepBox />
            <h5 class="mt-4 mb-2 px-2 fw-bold"><i class="far fa-palette me-2 text-primary"></i>아트 테크</h5>
            <ul class="dealable_list art_tech_list mb-4">
                <li v-for="(item,index) in art_tech" :key="index" class="d-flex flex-column border-bottom pb-2" :class="{'border-bottom-0':art_tech.length-1 == index}">
                    <router-link :to="`/item/${item.idx}`" class="d-flex justify-content-between align-items-center pt-3 ps-3 pe-4">
                        <img :src="item.src" alt="art_tech_img">
                        <div class="d-flex flex-column art_tech_info">
                            <h6 class="fw-bold mb-2">{{item.name}}</h6>
                            <div>
                                <span class="d-flex justify-content-between align-items-center">
                                    <small class="fs-sm me-1">보유자</small>
                                    <span class="fs-sm">
                                        <h6 class="fw-bold d-inline">{{numberWithCommas(item.holder)}}</h6> 명
                                    </span>
                                </span>
                                <span class="d-flex justify-content-between align-items-center">
                                    <small class="fs-sm me-1">판매율</small>
                                    <span class="fs-sm">
                                        <h5 class="d-inline fw-bold fs-4">{{item.rate}}</h5> %
                                    </span>
                                </span>
                                
                                <span class="d-flex justify-content-between align-items-center">
                                    <small class="fs-sm me-1">잔여수량</small>
                                    <span><small class="fs-sm">{{numberWithCommas(item.remain)}} 개</small></span>
                                </span>
                            </div>
                        </div>
                    </router-link>
                    <div class="d-flex justify-content-between align-items-center px-3 py-2">
                        <span class="fs-sm"><i class="fal fa-stars me-2"></i>{{item.wish}} <i class="far fa-comment-lines mx-2"></i>{{item.comment}}</span>
                        <span class="badge complete" v-if="item.status=='complete'">판매 완료</span>
                        <span class="badge sale" v-else>판매중</span>
                    </div>
                </li>
            </ul>

            <h5 class="mt-4 mb-2 px-2 fw-bold"><i class="far fa-building me-2 text-primary"></i>프롭 테크 <small class="text-muted fs-xs">(공모예정)</small></h5>
            <ul class="dealable_list mb-4">
                <li v-for="(list,index) in dealable_list" :key="index" class=" position-relative border-bottom" :class="{'border-bottom-0':dealable_list.length-1 == index}">
                    <router-link :to="`/item/${list.idx}`" class="d-flex justify-content-between align-items-center p-3">
                    
                        <div class="full_bg bg_img" :style="{backgroundImage:'url('+list.src+')'}">
                            <div class="text-white">
                                <div class="p-3 col-xs-12 info_box text-start position-absolute bottom-0">
                                    <span class="mb-4 badge rounded-pill bg-primary">{{list.type}}</span>
                                    <h1 class="fw-bolder">{{list.name}}</h1>
                                    <p class="mt-2"><i class="fas fa-map-marker-alt"></i> {{list.address}}</p>
                                    <hr class="my-3">
                                    <div class="small">
                                        <div class="d-flex mb-2">
                                            <span class="label me-3">공모기간</span>
                                            <b>{{list.period}}</b>
                                        </div>
                                        <div class="d-flex mb-2">
                                            <span class="label me-3">공모대상</span>
                                            <b>{{list.target}}</b>
                                        </div>
                                        <div class="d-flex mb-2">
                                            <span class="label me-3">공모금액</span>
                                            <b>{{list.price}}</b>
                                        </div>
                                        <div class="d-flex mb-2">
                                            <span class="label me-3">공모가</span>
                                            <b>{{list.pop}}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="d-flex flex-column">
                            <h6 class="fw-bold">{{item.name}}</h6>
                            <div :class="{'text-danger':item.rate>0,'text-primary':item.rate<0}">
                                <h5 class="fw-bold fs-2">{{numberWithCommas(item.price)}}</h5>
                                <p class="d-flex align-items-center fs-sm"><i class="fas me-2" :class="{'fa-caret-up':item.figure>0,'fa-caret-down':item.figure<0,'fa-minus fs-xs':item.figure==0}"></i>{{item.figure}} ({{item.rate}}%)</p>
                            </div>
                        </div>
                        <i class="far fa-chevron-right"></i> -->
                    </router-link>
                </li>
            </ul>
            
            <div class="d-flex justify-content-between align-items-center shadow rounded mb-3 bg-primary">
                <router-link to="/guide" class="text-white p-4 w-100 overflow-hidden position-relative fw-bold fs-5 text-left" style="letter-spacing:2px; text-indent:5px;">마이파킹 투자가이드
                <img src="@/assets/img/bank.svg" alt="" height="70" class="position-absolute coin_img translate-middle-y">
                </router-link>
            </div>
        </div>
        <Access :accessCheck.sync="access" />
    </div>
</template>

<script>
import Access from '../Access.vue'
import StepBox from '../StepBox.vue'
export default {
    components:{
        Access,
        StepBox
    },
    data(){
        return{
            access:null,
            art_tech:[
                {
                    src:require('@/assets/img/sample/art_tech1.png'),
                    idx:0,
                    name:'김화백 (1932~1997)',
                    remain:4850,
                    rate:0.41,
                    holder:20,
                    status:'complete',
                    wish:9,
                    comment:0
                },
                {
                    src:require('@/assets/img/sample/art_tech2.png'),
                    idx:0,
                    name:'김화백 (1932~1997)',
                    remain:515220000,
                    rate:33,
                    holder:0,
                    status:'sale',
                    wish:21,
                    comment:0
                }
            ],
            dealable_list:[
                {
                    idx:1,
                    src:require('@/assets/img/sample_building.jpg'),
                    name:'댑스코 1호',
                    address:'충남 웨딩홀',
                    period:'20.11.25 ~ 12.04',
                    target:'충남 웨딩홀 (수익증권)',
                    price:'101억 8,000만 원',
                    pop:'5,000 원',
                    type:'공모예정'
                },
                
                {
                    idx:2,
                    src:require('@/assets/img/modern_building.jpg'),
                    name:'댑스코 2호',
                    address:'경북 상가건물',
                    period:'20.11.25 ~ 12.04',
                    target:'경북 상가건물 (수익증권)',
                    price:'101억 8,000만 원',
                    pop:'5,000 원',
                    type:'공모예정'
                },
            ]
        }
    },
    methods:{
        numberWithCommas(n){
            var parts=n.toString().split(".");
            return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
        }
    },
    created(){
        if(localStorage.getItem('access')){
            this.access=false
        }else{
            this.access=true
            console.log('최초실행')
        }
    }
}
</script>

<style lang="scss" scoped>
.main{
    // background: linear-gradient(180deg, #d9ebff 25%, transparent 40%);
    background: linear-gradient(180deg, #d9ebff 250px, transparent 450px);
    // background-color: #d9ebff;
}

.art_tech_list img{
    height: 100px;
    width: 130px;
    max-width: 100%;
    object-fit: cover;
}
.art_tech_info{
    width: calc(100% - 140px);
}
.custom_indent{
    padding-left: 36px;
}
.coin_img{
    right: 40px;
    top: 70%;
}
.badge.complete{
    background-color: #4caf502e;
    color: #28962d;
}
.badge.sale{
    background-color: #fdc53654;
    color: #ff9800;
}
.full_bg{
    width: 100%;
    height: 400px;
    position: relative;
}
.full_bg::before{
    position: absolute;
    // background-color: rgba(0,0,0,.5);
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 60%, transparent 100%);
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
</style>