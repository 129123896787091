<template>
    <div>
        <div class="shadow rounded-3">
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center h-px-50">
                    <span class="px-3" @click="sideOpen()"><i class="fal fa-bars"></i></span>
                    <div class="text-pale w-100 fs-px-13 opacity-25" @click="$router.push('/search')">목적지 또는 주소 검색</div>
                    <!-- <input type="text" class="form-control border-0 px-0" placeholder="목적지 또는 주소 검색"> -->
                    <div class="small flex-shrink-0 px-2">
                        <div class="d-flex align-items-center gap-1" v-if="!timeOpen" @click="timeOpen=!timeOpen">{{timezone[selectIdx]}} <i class="fa-xs fas fa-caret-down"></i></div>
                        <div class="text-info" v-else @click="timeOpen=!timeOpen">취소</div>
                    </div>
                </div>
                <div v-show="timeOpen" class="px-3 pb-2 timeOpen">
                    <p class="small mb-2">주차 예정 시간을 선택해 주세요.</p>
                    <ul class="d-flex flex-wrap text-center small">
                        <li class="rounded-3 bg-gray-300 py-1" v-for="(item, index) in timezone" :key="index" :class="{'bg-primary text-white':selectIdx===index}" @click="selectIdx=index">{{item}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            timeOpen:false,
            timezone:['30분', '1시간', '2시간', '3시간','4시간','6시간','8시간'],
            selectIdx:1
        }
    },
    methods:{
        sideOpen(){
            this.$store.commit('sideOpenFn',true);
        }
    }
}
</script>

<style lang="scss" scoped> 
.timeOpen{
    ul>li{
        width: 23%;
        margin-right: 2.6%;
        margin-bottom: 2.6%;
    }
    ul>li:nth-of-type(4n){
        margin-right: 0;
    }
}
</style>