<template>
    <div v-if="accessCheck" class="access_wrap">
        <div class="container">
            <h3 class="fw-bolder mb-4">접근 권한 안내</h3>
            <p class="text-muted">마이파킹을 편리하고 안전하게 이용하기 위해<br>
            다음과 같은 접근 권한이 필요합니다.</p>
            <ul class="py-3">
                <li class="flex-column py-3 border-bottom">
                    <div><i class="far fa-bell me-3"></i><b>알림</b><small class="text-muted ms-1">(선택)</small></div>
                    <div class="txt-box text-muted">공모 일정, 체결 알림 등의 앱 푸쉬 알림을 보내드립니다.</div>
                </li>
            
                <li class="flex-column py-3 border-bottom">
                    <div><i class="far fa-camera me-3"></i><b>카메라</b><small class="ms-1 text-muted">(선택)</small></div>
                    <div class="txt-box text-muted">계좌 개설을 위한 신분증 촬영을 진행합니다.</div>
                </li>
            
                <li class="flex-column py-3 border-bottom">
                    <div><i class="fas fa-fingerprint me-3"></i><b>생체 인증</b><small class="ms-1 text-muted">(선택)</small></div>
                    <div class="txt-box text-muted">빠른 로그인 및 투자를 위해 휴대폰에 등록된 얼굴인식,<br>지문인식 등의 생체인증을 사용할 수 있습니다.</div>
                </li>
            </ul>
            <div class="footer_btn bg-white">
                <button class="btn btn-primary w-100" @click="accessAgree()">확인</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        accessCheck:{
            type:Boolean,
            default: true,
            mobile: false
        }
    },
    mounted() {
        var broswerInfo = navigator.userAgent;
        if(broswerInfo.indexOf("HYBRID_APPLICATION")>-1){
            this.mobile = true;
        }
    },
    methods:{
        accessAgree(){
            if(this.mobile) this.AccessREQUEST();
            this.$emit('update:accessCheck',!this.accessCheck);
            localStorage.setItem('access', true);
        },        
        AccessREQUEST(){
            try {
                if( /Android/i.test(navigator.userAgent.toLowerCase())) {
                    // Android
                    window.myHybridDabsco.accessRequest();
                }
                else if( /iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase())) {
                    // Iphone
                    window.webkit.messageHandlers.myHybridDabsco.accessRequest();
                }
            }catch(e) {
                console.log("accessRequest Error : "+e);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.access_wrap{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    padding-top: 3rem;
    box-sizing: border-box;
    
    .container{    
        height: calc(100vh - 70px);
        overflow-y: auto;
    }
    .txt-box{
        padding-left: 30px;
        font-size: 12px;
    }
}
.footer_btn{
    bottom: 0px;
}
</style>