<template>
    <div class="main">
      <div class="container pt-4">
        <StepBox class="position-relative zindex-1" />
        <div class="dimmed" v-show="$store.state.sideOpen" @click="sideOpen()"></div>
        <SideNav :class="{'on':$store.state.sideOpen}"></SideNav>


        <div class="map" ref="mapContainer">
            <!-- <img :src="imageUrl" class="zoomable-image" /> -->
            <!-- <div class="zoomable-image" :class="{'misa':$route.params.location==='misa','gangnam':$route.params.location==='gangnam'}">
              <div class="rounded-circle text-center map-item common shadow" @click="enlargePath()">일반<h6>1</h6></div>
              <div class="rounded-circle text-center map-item franchise shadow" @click="enlargePath()">주차권<h6>1</h6></div>  
            </div> -->
            <naver-maps class="zoomable-image" :map-options="mapOptions" @load="onLoad">
              <naver-info-window
                class="info-window"
                :is-open="true"
                :marker="markers"
              >        
              </naver-info-window>
               
              <naver-marker v-for="(item,i) in markers" :key="i" :lat="item.lat" :lng="item.lng" @click="onMarkerClicked(i)" @load="onMarkerLoaded">
                <div>
                  <span class="badge text-center map-item shadow" :class="{'on':item.sell.status==1, 'off':item.sell.status==0}">{{item.sell.title}}권 <h6>{{item.sell.price.toLocaleString()}}</h6></span>
                </div>
              </naver-marker>
            </naver-maps>
            <TabBar />
        </div>
        
        <!-- <div class="zoom-in-out position-fixed bg-white shadow">
          <div class="px-2 py-1 border-bottom"><i class="far fa-plus text-primary" @click="enlargePath()"></i></div>
          <div class="px-2 py-1"><i class="far fa-minus text-gray-400" ></i></div>
        </div> -->
      </div>
      <InfoPopup ref="InfoPopup" />
    </div>
  </template>
  
  <script>
  import SideNav from '@/components/common/SideNav.vue'
  import TabBar from '@/components/common/TabBar.vue'
  import StepBox from '@/components/StepBox.vue'
  import InfoPopup from '@/components/reservation/InfoPopup.vue'
  
  export default {
    name: 'Main',
    components: {
      StepBox, InfoPopup, SideNav,TabBar
    },
    data() {
      return {
        marker: null,
        markers: [
          //{
          //  lng: 127.215404,
          //  lat: 37.5436497,
          //  title : '타이틀1',
          //
          //  sell: {
          //    title: '1시간권',
          //    price: 5000,
          //    status: 1
          //  }
          //},
          //{
          //  lng: 127.104765,
          //  lat: 37.5135790,
          //  title : '타이틀2',
          //
          //  sell: {
          //    title: '1시간권',
          //    price: 5000,
          //    status: 1
          //  }
          //},
          {
            lng: 127.0451749,
            lat: 37.518136,
            title : '청담빌딩',

            sell: {
              title: '평일 1시간',
              price: 5000,
              status: 1
            }
          },
          {
            lng: 127.051588,
            lat: 37.5243529,
            title : '인희빌딩',

            sell: {
              title: '1시간',
              price: 5000,
              status: 1
            }
          },
          {
            lng : 127.0538529,
            lat : 37.5259066,
            title : '소니드빌딩',

            sell: {
              title: '1시간',
              price: 5000,
              status: 1
            }
          },
          {
            lng : 127.0541241,
            lat : 37.5238934,
            title : '호텔 리베라',

            sell: {
              title: '1시간',
              price: 4000,
              status: 0
            }
          }
        ]
        ,
        mapOptions: {
          lat: 127.0541241,
          lng: 37.5238934,
          // lat: 127.051576,
          // lng: 37.5243264,
          zoom: 15,
          zoomControl: true,
          zoomControlOptions: { 
            style: naver.maps.ZoomControlStyle.SMALL,
            position: naver.maps.Position.RIGHT_CENTER
          },
          mapTypeControl: false
        },
      }
    },
    methods:{
      // scrollXY(){
      //   const mapContainer = this.$refs.mapContainer;
      //   mapContainer.scrollTop = 600;
      //   mapContainer.scrollLeft = 800;
      // },
      // enlargePath(){
      //   document.querySelector('.zoomable-image').classList.add('enlarge');
      //   setTimeout(() => {
      //     this.$router.push(`/map/${this.$route.params.location}`)
      //   }, 300);
      // },
      
      sideOpen(){
        this.$store.commit('sideOpenFn',false)
      },
    
      onLoad(_map) {
        this.map = _map
        let initialLat = null;
        let initialLng = null;            
        // 조건에 따라 초기 좌표 설정 변경
        if (this.$route.params.location === 'gangnam') {
          initialLat = 37.5259066;
          initialLng = 127.0538529;

          // 좌표설정
          this.map.setCenter(new naver.maps.LatLng(initialLat, initialLng));
          this.mapOptions.lat = initialLat;
          this.mapOptions.lng = initialLng;

        }else if(this.$route.params.location === 'songpa'){
          initialLng = 127.051588;
          initialLat = 37.5243529;
        
          this.map.setCenter(new naver.maps.LatLng(initialLat, initialLng));
          this.mapOptions.lat = initialLat;
          this.mapOptions.lng = initialLng;

        } else{
        navigator.geolocation.getCurrentPosition(_position => {
          this.map.setCenter(
            _position.coords.latitude,
            _position.coords.longitude,          
          )
          this.mapOptions.lat = _position.coords.latitude
          this.mapOptions.lng = _position.coords.longitude
        })

        }
      },
      onMarkerLoaded(vue) {
        this.marker = vue.marker;
      },
      onMarkerClicked(i) {
        
        //if(i ==0){
        //  this.$refs.InfoPopup.item_id = 0;
        //}else if(i ==1){
        //  this.$refs.InfoPopup.item_id =1;
        //}

        this.$refs.InfoPopup.item_id = i;
        
        this.$refs.InfoPopup.pp = true
        // this.$router.push(`/map/${this.$route.params.location}`)
      }
    },
    mounted(){
      let location = this.$route.params.location;
      if(location==='gangnam'){
        this.map.setCenter(new naver.maps.LatLng(37.5259066, 127.0538529))
        
        this.mapOptions.lat = 37.5259066
        this.mapOptions.lng = 127.0538529
      }
      // this.scrollXY();

      // window.onload = () =>{
      //   this.scrollXY();
      // }

      // this.onMarkerLoaded() 

    }
  };
  </script>
  
<style lang="scss" scoped>  

.dimmed{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 999;
}
.zoom-in-out{
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  border-radius: 4px;
  width: 32px;
  text-align: center;
}

.map {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
}
.zoomable-image {
    min-width: 100%;
    min-height: 100%;
    /*&.misa{
      background-image: url('../assets/img/map/misa (2).png');
      .franchise{
        left: 50%;
        bottom: 50%;
      }
      .common{
        left: 48%;
        bottom: 48%;
      }
    }
    &.gangnam{
      background-image: url('../assets/img/map/gangnam (2).png');
      .franchise{
        left: 52%;
        bottom: 52%;
      }
      .common{
        left: 50%;
        bottom: 51%;
      }
    }*/
}

.map-item{
  position: absolute;
  width: 76px;
  padding-top: 0.5em;
  display: flex;
  color: #fff;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  border: 2px solid #00000033;


  &.on{ 
    background-color: #558bffe6;
  }
  &.off{
    background-color: #939090e3;
  }
  
  
  &.on::after{color: #6d98f5; filter: drop-shadow(0px -2px 0px #4469b9)}

  &.off::after{color:#a29b9b; filter: drop-shadow(0px -2px 0px #7e7b7a)}
  
  &::after{
    position: absolute;
    bottom: -4px;
    left: calc(50% - 6px);
    content: " ";
    height: 0;
    border-bottom: 6px solid;
    border-left: 6px solid rgba(0, 0, 0, 0);
    border-right: 6px solid rgba(0, 0, 0, 0);
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
  }

}
.enlarge{
  background-size: 250%;
}
</style>
  