<template>
    <div class="search">
        <div class="d-flex align-items-center justify-content-between border-bottom border-gray-200 container">
            <i class="fal fa-chevron-left close" @click="$router.go(-1)"></i>
            <input type="text" class="form-control border-0" placeholder="목적지 또는 주소 검색"  v-model="searchTerm"
     ref="autofocus">
        </div>
        <div class="container">
            <ul v-if="filteredLocations.length&&searchTerm!==''" class="py-3">
                <li v-for="(location, index) in filteredLocations" :key="index" class="d-flex flex-column mb-4" @click="searchFn(location.code)">
                    <p>{{ location.name }}</p>
                    <small class="text-muted">{{ location.location }}</small>
                    <small v-if="location.sell"><span class="text-primary me-1">{{location.sell}}</span>판매 중</small>
                </li>
            </ul>
            <p class="text-gray-500 fs-sm p-3 py-5 text-center" v-else>
                <i class="far fa-search-location fa-4x d-block mb-4"></i>
                검색 결과를 찾을 수 없습니다.</p>
            <!-- <NoticeList :list="currentList" :title="type" /> -->
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import NoticeList from "@/components/notice/NoticeList";
export default {
    components:{Header,NoticeList},
    data(){
        return{
            map:null,
            location:[
                {
                    code:'gangnam',
                    name:'청담빌딩 주차장',
                    location:'서울특별시 강남구 테헤란로 114',
                    sell:'평일 당일권 5,000원'
                },
                {
                    code:'songpa',
                    name:'인희빌딩 주차장',
                    location:'서울특별시 송파구 올림픽로 300',
                },
                {
                    code:'gangnam',
                    name:'소니드빌딩 주차장',
                    location:'서울 강남구 도산대로 549',
                    sell:'평일 1시간 5,000원'
                },
                {
                    code:'gangnam',
                    name:'호텔 리베라 주차장',
                    location:'서울 강남구 영동대로 737',
                },
            ],
            searchTerm:'',
            mapOptions: {
            lat: 127.0541241,
            lng:  37.5238934,
            zoom:15,
            }

        }
    },
    computed: {
        filteredLocations() {
            return this.location.filter(loc =>
                loc.location.includes(this.searchTerm)
            );
        },
    },
    mounted(){
        this.$nextTick(() => this.$refs.autofocus.focus())
    },
    methods:{
        searchFn(code){
            this.$router.push(`/main/${code}`)
        }
    }
}
</script>

<style lang="scss" scoped>
input::placeholder{
    color: rgba(0, 0, 0, 0.315);
}
.search{
    
    max-width: 768px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 58px;
    background-color: #fff;
    z-index: 99;
    width: 100%;

    >div{
        width: 100%;
        height: 100%;
        // padding: 0 .5em;
    }
    
}
    .menu_list_item{
        font-size: 15px;

        .tit{
            font-weight: 500;
        }
    }
</style>